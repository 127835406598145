$(function () {
    /**
     * HOME COVER MODULE
     */
    var coverSliderElement = $('.coverSlider')

    if (coverSliderElement.length) {
        coverSliderElement.each(function () {

            if ($(this).find('.swiper-slide').length > 1) {
                new Swiper("#" + $(this).attr('id'), {
                    preloadImages: false,
                    lazy: true,
                    loop: true,
                    speed: 1500,
                    autoplay: {
                        delay: 3000,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    }
                });
            }

            if ($(this).next().attr('class') != 'home-module multiple-bloc-4-blocs') {
                $(this).css('margin-bottom', '0px');
            }
        });
    }

    var reinsuranceMarquee = new Swiper('.reinsuranceMarquee', {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 24,
        speed: 5000,
        autoplay: {
            delay: 1,
        },
    });

    /**
     * HOME SOCIAL SLIDERS
     */
    var socialModuleSwiper1 = new Swiper('.socialModuleSwiper1', {
        direction: 'vertical',
        slidesPerView: 1.5,
        spaceBetween: 30,
        loop: true,
        speed: 6000,
        autoplay: {
            delay: 10,
        },
    });

    var socialModuleSwiper2 = new Swiper('.socialModuleSwiper2', {
        direction: 'vertical',
        slidesPerView: 1.5,
        spaceBetween: 30,
        loop: true,
        speed: 6000,
        autoplay: {
            delay: 10,
            reverseDirection: true
        },
    });

    /**
     * MODULE PAGE INACTIVE
     */
    var leftColSlider = new Swiper('.leftColSlider', {

        direction: 'vertical',
        spaceBetween: 30,
        centeredSlides: true,
        speed: 6000,
        autoplay: {

            delay: 1,
        },
        loop: true,
        slidesPerView: 2
    });

    var rightColSlider = new Swiper('.rightColSlider', {

        direction: 'vertical',
        spaceBetween: 30,
        centeredSlides: true,
        speed: 6000,
        autoplay: {

            delay: 1,
            reverseDirection: true
        },
        loop: true,
        slidesPerView: 2
    });

    /**
     * Création du sous-titre des blocs choix de livraison
     */
    if($('.step_2').length) {

        $('.bloc_livraison').each(function () {

            var labelTxt = '<span class="innerSpan">' + Translator.translate('delivered_by') + ' ' + $(this).children('.transporteur_info').html() + '</span>';

            // Update text form colissimo
            if ($(this).hasClass('transporteur_colissimo') && !$(this).hasClass('relais')) {
                var labelTxt = '<span class="innerSpan">' + Translator.translate('standard_delivery') + '</span>';
            }

            // Update text form UPS
            if ($(this).hasClass('transporteur_UPS')) {
                var labelTxt = '<span class="innerSpan">' + Translator.translate('express_delivery') + '</span>';
            }

            $(this).children('.title_type_livraison').append(labelTxt);
        });
    }

    /**
     * Deplacement des infos de commande pour la page paiement
     */
    if($('.checkout').length) {

        $('.wrapper_payment').insertBefore($('#cart_total'));
    }

    /**
     * Toggle txt SEO - Rayon
     */
     if ($('.category .txt_content_container .see_more_txt').length) {

        $('.txt_content_container .see_more_txt').on('click tap touch', function() {
            var heightToToggle = $(this).siblings('.txt_content').height();

            if ($(this).parent('.txt_content_container').hasClass('actif')) {

                $(this).parent('.txt_content_container').removeClass('actif');
                $(this).parent('.txt_content_container').animate({height: "71px"}, 500);
                $(this).children('.see_more_txt_cta').text(Translator.translate('txt_show_more'));

            } else {

                $(this).parent('.txt_content_container').addClass('actif');
                $(this).parent('.txt_content_container').animate({height: heightToToggle + 40}, 500);
                $(this).children('.see_more_txt_cta').text(Translator.translate('txt_show_less'));
            }
        })
    }

    /**
     * Toggle txt SEO - PRODUCT PAGE
     */
    if ($('.product_page .txt_content_container .see_more_txt').length) {

        $('.txt_content_container .see_more_txt').on('click tap touch', function() {
            var heightToToggle = $('.txt_content_container .txt_content').height();

            if ($('.txt_content_container').hasClass('actif')) {

                $('.txt_content_container').removeClass('actif');
                $('.txt_content_container').animate({height: "71px"}, 500);
                $('.see_more_txt_cta').text(Translator.translate('txt_show_more'));

            } else {

                $('.txt_content_container').addClass('actif');
                $('.txt_content_container').animate({height: heightToToggle + 40}, 500);
                $('.see_more_txt_cta').text(Translator.translate('txt_show_less'));
            }
        })
    }

    //Txt Input alerte stock
    if ($('#mail_alerte_stock').length) {
        $('#mail_alerte_stock + .w-input-label span').text(Translator.translate('your_email'));
        $('#keep_alive_stock .w-form-line.w-submit .w-submit-button span').text('ok');
    }

    // hide price order GBE & replace link returns in footer
    setTimeout(function(){
        checkImgFlagGe();
    }, 3000);

    setTimeout(function(){
        if ($('#ge_flag').length && $('body').hasClass('fr')) {
            $('#appContainer .wrap_total_commande').show();
            $('#appContainer .cmd_items .pricetag').css('opacity',1);
            $('#appContainer .wrapper_mes_commandes .montant').css('opacity',1);
        }
    }, 1500);

    $('.js-content li p').on('click',function() {
        $(this).toggleClass('active');
        $(this).next().toggleClass('active');
    })

    // Initialize all multi visual swipers on the category page
    if ($('body.category').length && $('#search_page').length === 0) {
        setTimeout(() => {
            initialiseMultiVisualSwipers();
        }, 1000);
    }

    // Custom event fired when a search is complete
    window.addEventListener("search_success", function(event) {
        initialiseMultiVisualSwipers();
    });

    $(".bloc_lightbox_preco").on("click",function() {
        if (!$(this).hasClass("actif")) {
            $(".bloc_lightbox_preco.actif").removeClass("actif");
            $(this).addClass("actif");
        }
    });
    
    // For site-map.php: Hide sitemap_section if there is no content to display
    if ($('body').hasClass('footer_sitemap')) {
        $('.sitemap_section').each(function() {
            if ($(this).children().length === 0) {
                $(this).hide();
            }
        });
    }
});

/***** PROD ASSOCS DATA LAYERS *****/
function dataLayer_associated_product() {

    if (window.$('#gondole_aimerez')) {
        var product_id = this.getAttribute('data-prod') ? event.currentTarget.getAttribute('data-prod') : '';

        if (product_id !== '') {
            var productObj = data_layer_products[product_id];

            var prod = productObj;
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list': productObj['category']},      // Optional list property.
                        'products': [prod]
                    }
                },
                'eventCallback': "function () {document.location = productObj.url}"
            });
        }
    }
}

/* UPSELLING ALMA PAGE LIVRAISON */

var upselling_alma_livraison = $('#cart_total .upselling_alma');

setTimeout(function(){
    if ($('.cart.step_2.in_tunnel #cart_total .upselling_alma').length) {
        upselling_alma_livraison.appendTo('#formPanier');
    }
    // check if cookie globalE
    if ( getCookie( "GlobalECartId" ) != '' ) {
        $( '.upselling_alma' ).attr( 'style', 'display: none !important; opacity: 0;' );
    } else {
        $( '.upselling_alma' ).attr( 'style', 'display: flex; opacity: 1' );
    }
}, 50);

/* CHECK COOKIE GLOBAL E */
function checkCookieGlobalE() {
    if(getCookie("GlobalECartId") != '') {
        if (typeof GEM_Services != "undefined" && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != undefined) {
            var isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
            if (isOperatedByGlobalE) {
                var linkReturnsFooter = $('.footer_content .list_links:first-child a:nth-child(5)');
                linkReturnsFooter.attr('href', gbe_traking_link);

            }
        }
    }
}

setTimeout(function(){
    checkCookieGlobalE();
}, 50);

/* REPLACE URL FOOTER & hide montant in order & customer page  */
function checkImgFlagGe() {
    if (!$('body').hasClass('fr') && $('#ge_flag').length) {
        var linkReturnsFooter = $('.footer_content .list_links:first-child a:nth-child(5)');
        linkReturnsFooter.attr('href', gbe_traking_link);
        //detail order
        if ($('body.customer').length) {
            $('body.customer .montant').css('opacity', 0);
        }
        if ($('body.order').length) {
            $('body.order .wrap_total_commande').css('opacity', 0);
            $('body.order .cmd_items .pricetag').css('opacity', 0);
        }
    }
}

// Initialise all MultiVisual product swipers. (We do this JS to avoid looping duplicate glitches)
function initialiseMultiVisualSwipers() {
    if ($('.productVisualMulti').length) {
        $('.productVisualMulti').on('mouseenter', function () { // Initialise a specific swiper only when mouse hovers on it
            const swiperContainer = $(this);
            
            // Check if the Swiper has already been initialized
            if (!swiperContainer.data('swiper-initialized')) {
                let swiperInstance = swiperContainer[0].swiper;

                // Destroy existing Swiper instance if it exists
                if (swiperInstance) {
                    swiperInstance.destroy(true, true);
                }

                // Initialize a new Swiper instance
                swiperInstance = new Swiper(swiperContainer[0], {
                    loop: true, // Enable looping
                    slidesPerView: 1,
                    navigation: {
                        nextEl: '.rollover_rayonsNext',
                        prevEl: '.rollover_rayonsPrev',
                    }
                });

                // Update the Swiper instance
                swiperInstance.update();

                // Mark this container as initialized
                swiperContainer.data('swiper-initialized', true);

                // Prevent default action for next and prev button clicks (only for the search page)
                if ($('#search_page').length) {
                    const nextButton = swiperContainer.find('.rollover_rayonsNext')[0];
                    const prevButton = swiperContainer.find('.rollover_rayonsPrev')[0];

                    if (nextButton) {
                        nextButton.addEventListener('click', function(e) {
                            e.preventDefault();  // Prevent the default action of the <a> tag
                            e.stopPropagation(); // Stop the event from bubbling up
                            swiperInstance.slideNext();
                        });
                    }

                    if (prevButton) {
                        prevButton.addEventListener('click', function(e) {
                            e.preventDefault();  // Prevent the default action of the <a> tag
                            e.stopPropagation(); // Stop the event from bubbling up
                            swiperInstance.slidePrev();
                        });
                    }
                }
            }
        });
    }
}