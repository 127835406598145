alert = (function() {
    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {
        shade = document.getElementById("shad_abox");
        albox = document.getElementById("abox");

        alert_tmpl = albox.innerHTML.trim();

        window.removeEventListener("load", init);
    }

    function closeAlertBox() {
        $(albox).removeClass('actif');
        setTimeout(function() {
            $(albox).fadeOut(400, function() {
                $("#abox .form_submit").css("display", "block");
            });
            $(shade)
                .off("click", closeAlertBox)
                .removeClass('actif');
            $("body").removeClass("alert_open");
        },300)
    }

    function alert(str) {
        $("body").addClass("alert_open");

        $(".txt_alert", albox).html(str);

        $(".close", albox).on("click", closeAlertBox);
        $(shade).one("click", closeAlertBox);

        //window.scroll(0, 0);

        $(shade).addClass('actif');
        $(albox).addClass('actif');
        $(albox).fadeIn(400);
    }

    window.addEventListener("load", init);

    return alert;
})();
