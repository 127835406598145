// // V�rification d'un bouton radio
function verif_radio(elementID, fieldname) {
    if (
        elementID == "clientCiviliteM" ||
        elementID == "clientCiviliteMme" ||
        elementID == "clientCiviliteMlle"
    )
        elementID = "clientCivilite";

    if (
        elementID == "optin_y" ||
        elementID == "optin_n"
    )
        elementID = "optin";
    var objElement = document.getElementsByName(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var trouve = false;
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (elementID) {
        $("input[name='" + elementID + "']").each(function (idx) {
            if ($(this).is(":checked") == true) {
                objElement.className = "inputForm";
                trouve = true;
            }
        });
        if (!trouve) {
            if (elementID == "clientCivilite") {
                t_error =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_civilite&arg2=" +
                        fieldname
                    );
            } else {
                t_error =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_thanks&arg2=" +
                        fieldname
                    );
            }

            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        } else {
            $(objElement).removeClass("inputErr");
            $(objLabel).removeClass("labelErr");
        }
    }
    return true;
}

function verif_price(e, t) {
    var a = document.getElementById(e),
        r = document.getElementById(e + "Label"),
        l = (document.getElementById("mes_err_fieldset"),
            document.getElementById("erreur_msg"));
    if (
        ($(a)
            .removeData("data-error-text")
            .removeAttr("data-error-text"),
        "" == a.value)
    )
        return (
            (t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + t) +
                " :</p>" +
                ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t)),
                $(a).data("data-error-text", t_error),
                (l.innerHTML = t_error),
                (a.className = "inputErr"),
                (r.className = "labelErr"),
                !1
        );
    var n = validate_price(a.value);
    return 0 == n
        ? ((t_error =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + t) +
            " :</p>" +
            ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t)),
            $(a).data("data-error-text", t_error),
            (l.innerHTML = t_error),
            (a.className = "inputErr"),
            (r.className = "labelErr"),
            !1)
        : ((a.className = "inputForm"), (r.className = "labelForm"), !0);
}

function validate_price(e) {
    return /^[\d\.,]+$/.test(e);
}

// V�rificartion d'une case coch�
function verif_checkbox(elementName, fieldname) {
    var objElement = document.getElementsByName(elementName);
    var objLabel = document.getElementById(elementName.slice(0, -2) + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (elementName) {
        objElement.className = "inputForm";
        objLabel.className = "labelForm";
        for (i = 0; i < objElement.length; i++)
            if (objElement[i].checked == true) {
                objElement.className = "inputForm";
                objLabel.className = "labelForm";
                return true;
            }
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    return true;
}

function btnLoaderUnloading(btn) {

    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {

        setTimeout(function () {
            loader.removeAttr("style").parent().removeClass("loading")
        }, 200);
    }
}

function verif_checkbox_index(elementName, fieldname, pIndex) {
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (document.prodForm.visuStatutDetail1) {
        if (document.prodForm.visuStatutDetail1[0].checked == true) {
            return true;
        }
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_photo_validate"
        );
        return false;
    } else {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_photo_validate"
        );
        return false;
    }
}

function verif_zip(elementZipID, elementCountryID, fieldname, nb) {
    var objZipElement = document.getElementById(elementZipID);
    var objCountryElement = document.getElementById(elementCountryID);
    var objLabel = document.getElementById(elementZipID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");

    var idPays = objZipElement.value.substr(0, 2);

    if (objCountryElement.value == "DO" && idPays != "97") {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                "ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=" +
                fieldname +
                "&arg3=97"
            );
        $(objZipElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    } else if (objCountryElement.value == "MO" && idPays != "98") {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                "ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=" +
                fieldname +
                "&arg3=98"
            );
        $(objZipElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    } else if (
        objCountryElement.value == "TO" &&
        idPays != "97" &&
        idPays != "98"
    ) {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                "ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=" +
                fieldname +
                "&arg3=97ou98"
            );
        $(objZipElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    } else if (
        objCountryElement.value != "DO" &&
        objCountryElement.value != "TO" &&
        objCountryElement.value != "MO" &&
        (idPays == "97" ||
            (idPays == "98" &&
                objCountryElement.value != "FR" &&
                objCountryElement.value != "US"))
    ) {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                "ajax_sprintf.php?arg1=js_error_zip_special_2&arg2=" +
                fieldname +
                "&arg3=" +
                idPays
            );
        $(objZipElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    }

    $(objZipElement).removeClass("inputForm");
    $(objLabel).removeClass("labelErr");
    return true;
}

function verif_num(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var point = 0;
    var virgule = 0;

    if (objElement.value == "") {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" +
                fieldname
            );
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");

        return false;
    } else {
        if (objElement.value.length < nb) {
            erreurText.innerHTML =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                    "ajax_sprintf.php?arg1=js_at_least_x_digits&arg2=color:red;&arg3=" +
                    fieldname +
                    "&arg4=" +
                    nb
                );
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurText.innerHTML =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                    "ajax_sprintf.php?arg1=js_wrong_input_num&arg2=" +
                    fieldname
                );
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            flg = 0;
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (test == ".") {
                point = point + 1;
            }
            if (test == ",") {
                virgule = virgule + 1;
            }
        }
        var tot = point + virgule;
        if (point > 1 || virgule > 1 || tot > 1) {
            erreurText.innerHTML = erreurText.innerHTML =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                    "ajax_sprintf.php?arg1=js_wrong_input_num&arg2=" +
                    fieldname
                );
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            flg = 0;
            return false;
        }
    }
    $(objElement).removeClass("inputErr");
    $(objElement).removeClass("labelErr");
    return true;
}

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                        fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}

function verif_ref_interne(elementID, fieldname) {
    var flg = 0;
    var alphanum =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 9
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                fieldname +
                "&arg3=9"
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 9) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=9&arg3=" +
                fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        if (objElement.value.length > 14) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_ref_14_chars&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=" +
                fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_alphanum_codepromo(elementID, fieldname) {
    var flg = 0;
    var alphanum =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,-_/.<>()%:;";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 4
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                fieldname +
                "&arg3=4"
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 4) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=4&arg3=" +
                fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=" +
                fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_empty(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (objElement.value == "") {
        t_error =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
            " :</p>" +
            ajax_file(
                "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
            );
        erreurText.innerHTML = t_error;
        $(objElement).data("data-error-text", t_error);
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        $(objElement).removeClass("inputForm");
        $(objLabel).removeClass("labelForm");
        return false;
    }
    $(objElement).addClass("inputForm");
    $(objLabel).addClass("labelForm");
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function verif_alphanum(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum =
        decodeURIComponent("%C2%B0") +
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"-_/.<>\n\r()%:; " +
        decodeURIComponent(
            "%C3%A2%C3%A0%C3%A4%C3%A9%C3%A8%C3%AB%C3%AA%C3%AC%C3%AF%C3%AE%C3%B6%C3%B4%C3%B2%C3%B9%C3%BC%C3%BB%C3%A7&%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8A%C3%8C%C3%8F%C3%8E%C3%96%C3%94%C3%92%C3%99%C3%9C%C3%9B%C3%87"
        );
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (objElement.value == "") {
        t_error =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
            " :</p>" +
            ajax_file(
                "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
            );
        $(objElement).data("data-error-text", t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 3
        ) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                    fieldname +
                    "&arg3=3"
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=" +
                    fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) {
                flg++;
            }
        }
        if (flg > 0) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=" +
                    fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=" +
                    fieldname +
                    "&arg3=" +
                    length
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
    }
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function verif_alphanumadresse(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"_/.<>\n\r()%:; " +
        decodeURIComponent(
            "%C3%A2%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%26%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%3F!%E2%82%AC-"
        );
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (objElement.value == "") {
        t_error =
            '<p class="title_toolltip">' +
            ajax_file(
                path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
            ) +
            " :</p>" +
            ajax_file(
                path_relative_root +
                "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" +
                fieldname
            );
        $(objElement).data("data-error-text", t_error);
        erreurText.innerHTML = t_error;
        objElement.className = "inputErr";
        if (objLabel) objLabel.className = "labelErr";
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 3
        ) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                    "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                    fieldname +
                    "&arg3=3"
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            objElement.className = "inputErr";
            if (objLabel) objLabel.className = "labelErr";
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                    "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=" +
                    fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            objElement.className = "inputErr";
            if (objLabel) objLabel.className = "labelErr";
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                    "ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=" +
                    fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            objElement.className = "inputErr";
            if (objLabel) objLabel.className = "labelErr";
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file(
                    path_relative_root + "ajax_sprintf.php?arg1=" + fieldname
                ) +
                " :</p>" +
                ajax_file(
                    path_relative_root +
                    "ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=" +
                    fieldname +
                    "&arg3=" +
                    length
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            return false;
        }
    }
    objElement.className = "inputForm";
    if (objLabel) objLabel.className = "labelForm";
    return true;
}

function verif_textarea(elementID, fieldname) {
    var flg = 0;
    var alphanum =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"\\n\r-_/.()%:; �����i�������";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "textareaErr";
        objLabel.className = "labelErr";
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 3
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                fieldname +
                "&arg3=3"
            );
            objElement.className = "textareaErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 3) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=" +
                fieldname
            );
            objElement.className = "textareaErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=" +
                fieldname
            );
            objElement.className = "textareaErr";
            objLabel.className = "labelErr";
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = "textarea";
    objLabel.className = "labelForm";
    return true;
}

function verif_textarea2(elementID, fieldname) {
    var flg = 0;
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "textareaErr";
        objLabel.className = "labelErr";
        return false;
    }
    if (objElement.value.length < 3) {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=" + fieldname
        );
        objElement.className = "textareaErr";
        objLabel.className = "labelErr";
        return false;
    }
    objElement.className = "textarea";
    objLabel.className = "labelForm";
    return true;
}

function verif_mail(elementID, fieldname, is_spe) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var mail = new RegExp(
        "^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$",
        "gi"
    );

    path = "";
    if (typeof path_relative_root != "undefined") path += path_relative_root;

    if (mail.exec(objElement.value) == null) {
        if (typeof is_spe == "undefined") {
            var translations = ajax_file(
                path +
                "ajax_sprintf.php?arg0=%s|%s" +
                "&arg1=" +
                fieldname +
                "&arg2=js_wrong_input_mail"
            );
            translations = translations.split("|");
            type_champ = translations[0];
            type_message = translations[1];

            erreurText.innerHTML =
                '<p class="title_toolltip">' +
                type_champ +
                " :</p>" +
                type_message;
        }
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    }
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function verif_alpha(elementID, fieldname) {
    var flg = 0;
    var alphanum =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"-_/.ééÈèËëÊêÔôÎîÛûÂâÏïÄÖöäÜüÀàÙùÇç&n " +
        decodeURIComponent(
            "%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%C3%A4%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%C2%A0 "
        );
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (objElement.value == "") {
        t_error =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
            " :</p>" +
            ajax_file(
                "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
            );
        $(objElement).data("data-error-text", t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if (
            (space_after + 1 == objElement.value.length ||
                space_before + 1 == 1) &&
            objElement.value.length <= 2
        ) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=" +
                    fieldname +
                    "&arg3=2"
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
        if (objElement.value.length < 2) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=2&arg3=" +
                    fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                " :</p>" +
                ajax_file(
                    "ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=" +
                    fieldname
                );
            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            flg = 0;
            return false;
        }
    }
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function verif_format_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value != "") {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{2})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_date_1&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            return false;
        }
    } else {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_date_2&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_format_date2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement.value != "") {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{4})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_date_1&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            return false;
        }
    } else {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_date_2&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_format_date_select(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + "J");
    var objElementM = document.getElementById(elementID + "M");
    var objElementA = document.getElementById(elementID + "A");
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (
        objElementJ.value == "" ||
        objElementM.value == "" ||
        objElementA.value == ""
    ) {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
        );
        objElementJ.className = "inputErr";
        objElementM.className = "inputErr";
        objElementA.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    objElementJ.className = "inputForm";
    objElementM.className = "inputForm";
    objElementA.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_format_majorite(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + "J");
    var objElementM = document.getElementById(elementID + "M");
    var objElementA = document.getElementById(elementID + "A");
    var date = new Date();
    var annee = date.getFullYear();
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElementA.value > annee - 18) {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_majorite"
        );
        objElementJ.className = "inputErr";
        objElementM.className = "inputErr";
        objElementA.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    objElementJ.className = "inputForm";
    objElementM.className = "inputForm";
    objElementA.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_selected(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement) {
        var objLabel = document.getElementById(elementID + "Label");
        if (objElement.value == "") {
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_select&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            return false;
        } else {
            objElement.className = "inputForm";
            objLabel.className = "labelForm";
            return true;
        }
    }
    return true;
}

function verif_selected_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var objElementJ = document.getElementById(elementID + "J");
    var objElementM = document.getElementById(elementID + "M");
    var objElementA = document.getElementById(elementID + "A");
    if (objElementJ && objElementM && objElementA) {
        erreurText.innerHTML =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
            " :</p>" +
            ajax_file(
                "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
            );
        var error = false;
        if (objElementJ.value == "") {
            error = true;
            $(objElementJ).addClass("inputErr");
        } else {
            $(objElementJ).removeClass("inputErr");
        }
        if (objElementM.value == "") {
            error = true;
            $(objElementM).addClass("inputErr");
        } else {
            $(objElementM).removeClass("inputErr");
        }
        if (objElementA.value == "") {
            error = true;
            $(objElementA).addClass("inputErr");
        } else {
            $(objElementA).removeClass("inputErr");
        }
        if (!error) {
            $(objLabel).removeClass("labelErr");
            return true;
        } else {
            $(objLabel).addClass("labelErr");
            return false;
        }
    }
    return true;
}

function verif_selected2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objElement) {
        var objLabel = document.getElementById(elementID + "Label");
        if (objElement.value == "") {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_select&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            return false;
        } else {
            objElement.className = "inputForm";
            objLabel.className = "labelForm";
            return true;
        }
    } else {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_select&arg2=" + fieldname
        );
        return false;
    }
    return true;
}

function verif_categ(formID) {
    var objForm = document.getElementById(formID);
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (objForm.prodCadeau) {
        if (
            objForm.prodCadeau.checked == false &&
            !objForm.prodCateg_0.selectedIndex
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_categ_1"
            );
            return false;
        }
        if (
            objForm.prodCadeau.checked == false &&
            objForm.prodCateg_0.selectedIndex &&
            !objForm.prodSousCateg_0.selectedIndex
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_categ_1"
            );
            return false;
        }
        if (
            objForm.prodCadeau.checked == false &&
            objForm.prodCateg_0.selectedIndex &&
            objForm.prodSousCateg_0.selectedIndex &&
            !objForm.prodObjet_0.selectedIndex
        ) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_categ_1"
            );
            return false;
        }
    } else {
        if (!objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_categ_2"
            );
            return false;
        }
        $errTMP = false;
        for (x = 0; x <= 4; x++) {
            if (eval("objForm.prodCateg_" + x + ".selectedIndex"))
                if (!eval("objForm.prodObjet_" + x)) $errTMP = true;
                else if (!eval("objForm.prodObjet_" + x + ".selectedIndex"))
                    $errTMP = true;
        }
        if ($errTMP == true) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_error_categ_3"
            );
            return false;
        }
    }
    return true;
}

var indexImg = 0;

function nextImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg++;
        if (indexImg >= imgPath.length) indexImg = 0;
        MM_swapImage(elementID, "", imgPath[indexImg], 1);
    }
}

function prevImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg--;
        if (indexImg < 0) indexImg = imgPath.length - 1;
        MM_swapImage(elementID, "", imgPath[indexImg], 1);
    }
}

function NewWindow(mypage, myname, w, h, scroll) {
    LeftPosition = screen.width ? (screen.width - w) / 2 : 0;
    TopPosition = screen.height ? (screen.height - h) / 2 : 0;
    settings =
        "height=" +
        h +
        ",width=" +
        w +
        ",top=" +
        TopPosition +
        ",left=" +
        LeftPosition +
        ",scrollbars=" +
        scroll +
        ",resizable,";
    win = window.open(mypage, myname, settings);
}

function preg_replace(array_pattern, array_pattern_replace, my_string) {
    var new_string = String(my_string);
    for (i = 0; i < array_pattern.length; i++) {
        var reg_exp = RegExp(array_pattern[i], "gi");
        var val_to_replace = array_pattern_replace[i];
        new_string = new_string.replace(reg_exp, val_to_replace);
    }
    return new_string;
}

function verif_num_bandeau(elementID, fieldname) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        objElement.focus();
        return false;
    } else {
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_wrong_input_num_bandeau&arg2=" +
                fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

function verif_etage(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789rdcRDC.";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        objElement.focus();
        return false;
    } else {
        if (objElement.value.length > nb) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=" +
                nb +
                "&arg3=" +
                fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0) flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = "block";
            erreurText.innerHTML = ajax_file(
                "ajax_sprintf.php?arg1=js_wrong_input_num_rdc&arg2=" + fieldname
            );
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = "inputForm";
    objLabel.className = "labelForm";
    return true;
}

// Chargement des différentes tarification
$.ajax({
    url: path_relative_root + 'ajax_tarification_list.php',
    type: 'post',
    dataType: 'json',
    success: function (res) {
        if (res.length !== 0) {
            var div = $('<div>', {'class': 'custom-select custom-select-footer'});
            var select = $('<select id="country_tarif" name="country">');
            $.each(res, function (key, val) {
                var option = $("<option />", {value: key, text: val.pays_nom});
                if (val.selected) {
                    option.prop('selected', true)
                }
                $(option).appendTo(select);
            });
            // Event sur le select lorsque l'on change de pays
            select.on('change', function () {
                var country_iso = $(this).val();
                // On va envoyé au serveur que l'on change de pays
                $.ajax({
                    url: path_relative_root + 'ajax_tarification_change_country.php',
                    type: 'post',
                    data: 'iso=' + country_iso,
                    success: function () {
                        location.reload();
                    }
                });
            });
            select.appendTo(div);
            div.appendTo($("#tarification_load"));
            if (typeof initCustomSelect != "undefined") {
                initCustomSelect('custom-select-footer');

            }
        } else {
            $("#tarification_load").css('display', 'none');
        }
    }
});

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }

    document.cookie = name + "=" + value + expires + "; path=/";
}

function initAssociatedProductSlider() {
    // if there is no #gondole_aimerez element, we don't need to init the slider
    if ($('#gondole_aimerez').length === 0) {
        return;
    }

    // if there's more than 4 items show the pagination
    if ($('#gondole_aimerez .swiper-slide').length > 4) {
        $('#gondole_aimerez .swiper-pagination').show();
    } else {
        $('#gondole_aimerez .swiper-pagination').hide();
    }

    // init the slider
    new Swiper('#gondole_aimerez', {
        slidesPerView: 4,
        spaceBetween: 25,
        loop: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        watchOverflow: true,
    });
}

document.addEventListener('DOMContentLoaded', function () {
    initAssociatedProductSlider();
});

function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });

    // Suppression d'un produit dans la wishlish
    $(".remove_wish").click(function(ev) {
        var id = $(this).attr("data-id");

        $.post("ajax_remove_from_wishlist.php", {id: id}, function(data) {
            var nr;

            if (data.result == undefined) {
                nr = $('.wish_liste_product .product_ctn').length;

            } else {
                nr = data.result.wishlistLength;
            }

            $("#wproduct_" + id).fadeOut(100, function() {
                $(this).remove();
                scanForChanges();
            });

            if (nr<=0) {
                $('.wrapper_btn_add').remove();
                $('#nrArticlesSelected').remove();
            }
        });
    });
});